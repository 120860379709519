import { SVGProps } from 'react';

export default (props: SVGProps<SVGSVGElement>) => (
    <svg
        {...props}
        viewBox="0 0 11 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M5.07767 2.53846C5.07767 2.3048 5.26709 2.11539 5.50075 2.11539C5.73441 2.11539 5.92383 2.3048 5.92383 2.53846L5.92383 2.96154C5.92383 3.1952 5.73441 3.38462 5.50075 3.38462C5.26709 3.38462 5.07767 3.1952 5.07767 2.96154L5.07767 2.53846Z"
            fill="#E6007A"
        />
        <path
            d="M5.07767 4.65385C5.07767 4.42019 5.26709 4.23077 5.50075 4.23077C5.73441 4.23077 5.92383 4.42019 5.92383 4.65385L5.92383 8.46154C5.92383 8.69521 5.73441 8.88462 5.50075 8.88462C5.26709 8.88462 5.07767 8.69521 5.07767 8.46154L5.07767 4.65385Z"
            fill="#E6007A"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11 5.5C11 2.46242 8.53758 -2.15272e-07 5.5 -4.80825e-07C2.46242 -7.46379e-07 9.85203e-07 2.46242 7.1965e-07 5.5C4.54096e-07 8.53758 2.46242 11 5.5 11C8.53758 11 11 8.53758 11 5.5ZM5.5 0.846153C8.07028 0.846153 10.1538 2.92972 10.1538 5.5C10.1538 8.07028 8.07028 10.1538 5.5 10.1538C2.92972 10.1538 0.846154 8.07028 0.846154 5.5C0.846155 2.92972 2.92972 0.846153 5.5 0.846153Z"
            fill="#E6007A"
        />
    </svg>
);
