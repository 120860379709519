import { SVGProps } from 'react';

export default (props: SVGProps<SVGSVGElement>) => (
    <svg
        {...props}
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M8.9285 6.35221L14.5135 7.62939e-06H13.1905L8.339 5.51441L4.467 7.62939e-06H0L5.8565 8.33956L0 15H1.323L6.443 9.17536L10.533 15H15M1.8005 0.976194H3.833L13.1895 14.0718H11.1565"
            fill="#E6007A"
        />
    </svg>
);
