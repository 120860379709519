import { SVGProps } from 'react';

export default (props: SVGProps<SVGSVGElement>) => (
    <svg width="94" height="95" viewBox="0 0 94 95" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M85.6 33.4H66.5C65.2 35.2 63.7 36.9 61.9 38.3H85.6C87.1 38.3 88.3 39.5 88.3 41V87.5C88.3 89 87.1 90.2 85.6 90.2H7.6C6.1 90.2 4.9 89 4.9 87.5V40.9C4.9 39.4 6.1 38.2 7.6 38.2H31.3C29.6 36.8 28 35.2 26.7 33.3H7.6C3.4 33.3 0 36.7 0 40.9V87.4C0 91.6 3.4 95 7.6 95H85.6C89.8 95 93.2 91.6 93.2 87.4V40.9C93.2 36.8 89.8 33.4 85.6 33.4Z" fill="white"/>
        <path d="M44.4997 74.9C43.8997 70.4 40.6997 66.8 36.5997 65.4C34.4997 66.7 31.8997 67.5 29.1997 67.5C26.4997 67.5 23.9997 66.8 21.7997 65.4C17.6997 66.7 14.4997 70.4 13.8997 74.9L13.0997 80.5C12.8997 82 13.9997 83.3 15.4997 83.3H42.6997C44.1997 83.3 45.2997 82 45.0997 80.5L44.4997 74.9Z" fill="white"/>
        <path d="M29.1992 64C34.3992 64 38.6992 59.8 38.6992 54.5C38.6992 49.3 34.4992 45 29.1992 45C23.9992 45 19.6992 49.2 19.6992 54.5C19.6992 59.7 23.9992 64 29.1992 64Z" fill="white"/>
        <path d="M77.6996 53.2H54.9996C53.6996 53.2 52.5996 52.1 52.5996 50.8C52.5996 49.5 53.6996 48.4 54.9996 48.4H77.5996C78.8996 48.4 79.9996 49.5 79.9996 50.8C79.9996 52.1 78.9996 53.2 77.6996 53.2Z" fill="white"/>
        <path d="M77.6996 66.6H54.9996C53.6996 66.6 52.5996 65.5 52.5996 64.2C52.5996 62.9 53.6996 61.8 54.9996 61.8H77.5996C78.8996 61.8 79.9996 62.9 79.9996 64.2C80.0996 65.5 78.9996 66.6 77.6996 66.6Z" fill="white"/>
        <path d="M77.6996 80H54.9996C53.6996 80 52.5996 78.9 52.5996 77.6C52.5996 76.3 53.6996 75.2 54.9996 75.2H77.5996C78.8996 75.2 79.9996 76.3 79.9996 77.6C80.0996 79 78.9996 80 77.6996 80Z" fill="white"/>
        <path d="M46.6002 0C35.9002 0 27.2002 8.7 27.2002 19.4C27.2002 30.1 35.9002 38.8 46.6002 38.8C57.3002 38.8 66.0002 30.1 66.0002 19.4C66.0002 8.7 57.3002 0 46.6002 0ZM55.9002 17L45.9002 27C45.3002 27.6 44.6002 27.9 43.8002 27.9C43.1002 27.9 42.3002 27.6 41.7002 27L36.7002 22C35.6002 20.9 35.6002 19 36.7002 17.9C37.8002 16.8 39.7002 16.8 40.8002 17.9L43.7002 20.8L51.6002 12.9C52.7002 11.8 54.6002 11.8 55.7002 12.9C57.1002 14 57.1002 15.8 55.9002 17Z" fill="white"/>
    </svg>
);
