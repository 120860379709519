import { SVGProps } from 'react';

export default (props: SVGProps<SVGSVGElement>) => (
    <svg
        {...props}
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12.875 10.1726L17.81 14.4651C17.8773 14.2617 17.9175 14.0451 17.9175 13.8187V7.18396C17.9175 6.85419 17.8379 6.54575 17.6993 6.27013L12.875 10.1726Z"
            fill="#E70071"
        />
        <path
            d="M16.8134 5.36567C16.5295 5.21638 16.2088 5.13271 15.8659 5.13271H5.13454C4.80723 5.13271 4.49633 5.21228 4.2207 5.35092L10.6446 10.3549L16.8134 5.36567Z"
            fill="#E70071"
        />
        <path
            d="M10.6616 11.9635L9.37861 10.9636L3.96289 15.4983C4.29431 15.7313 4.69789 15.8683 5.13431 15.8683H15.8657C16.3111 15.8683 16.7221 15.7231 17.0609 15.4819L11.8798 10.9767L10.6616 11.9635Z"
            fill="#E70071"
        />
        <path
            d="M3.31532 6.24102C3.16848 6.52239 3.08398 6.84395 3.08398 7.18193V13.8158C3.08398 14.0529 3.12582 14.2801 3.20129 14.4918L8.36099 10.1721L3.31532 6.24102Z"
            fill="#E70071"
        />
    </svg>
);
