import { SVGProps } from 'react';

export default (props: SVGProps<SVGSVGElement>) => (
    <svg
        {...props}
        viewBox="0 0 9 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M7.16669 8H3.8334V9H7.16669C7.65291 9 8.11921 8.80686 8.46306 8.46306C8.80686 8.11919 9 7.65289 9 7.16667V1.83333C9 1.34711 8.80686 0.880806 8.46306 0.536945C8.11921 0.193139 7.65291 0 7.16669 0H1.83343C1.34722 0 0.880918 0.193139 0.537061 0.536945C0.19326 0.880806 0.000124018 1.34711 0.000124018 1.83333V6.89C-0.00443314 7.20278 0.116767 7.50426 0.33655 7.72681C0.55638 7.94931 0.856293 8.07431 1.16905 8.07365C1.48183 8.073 1.78124 7.94681 2.0001 7.72333L5.33338 4.37333V6.33333H6.33337V3.16667C6.33337 3.03407 6.28069 2.9069 6.18694 2.8131C6.09314 2.71935 5.96597 2.66667 5.83338 2.66667H2.66675V3.66667H4.62673L1.29344 7.00833C1.24694 7.06302 1.17067 7.08179 1.10409 7.05494C1.03753 7.02808 0.995589 6.96162 1.00009 6.88995V1.83328C1.00009 1.61226 1.08787 1.40028 1.24417 1.24403C1.40042 1.08773 1.61239 0.999951 1.83341 0.999951H7.16667C7.3877 0.999951 7.59967 1.08773 7.75592 1.24403C7.91222 1.40028 8 1.61226 8 1.83328V7.16662C8 7.38765 7.91221 7.59962 7.75592 7.75587C7.59967 7.91217 7.3877 7.99995 7.16667 7.99995L7.16669 8Z"
            fill="#777777"
        />
    </svg>
);
