import { SVGProps } from 'react';

export default (props: SVGProps<SVGSVGElement>) => (
    <svg
        {...props}
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_297_19)">
            <path
                d="M6.50002 12.3229C8.11938 12.3229 9.6744 11.6404 10.7681 10.4542C10.9144 10.2972 11.0444 10.1398 11.1744 9.97225C14.04 6.23421 11.2125 0.611706 6.50002 0.676706C1.79315 0.611706 -1.03998 6.23421 1.82565 9.96607C1.95565 10.1342 2.08565 10.2967 2.2319 10.4536C3.32628 11.6398 4.88065 12.3223 6.50002 12.3223V12.3229ZM6.50002 1.48957C10.5519 1.43575 12.9894 6.26708 10.5244 9.47948C10.4162 9.62573 10.2969 9.76639 10.1725 9.90198C8.30372 12.0251 4.69622 12.0251 2.82747 9.90198C2.70306 9.76639 2.58372 9.62573 2.46997 9.47389C0.0105914 6.26203 2.44814 1.43014 6.49434 1.48453L6.50002 1.48957Z"
                fill="#E70071"
            />
            <path
                d="M2.15609 10.0156C3.75422 6.26188 9.24672 6.26188 10.8442 10.0156C10.9092 10.1619 10.8823 10.335 10.7685 10.4544C8.59667 12.9244 4.40417 12.9244 2.23165 10.4544C2.12349 10.335 2.09607 10.1619 2.15599 10.0156H2.15609Z"
                fill="#E70071"
            />
            <path
                d="M6.49987 2.30186C8.34176 2.24752 9.48424 4.53374 8.34674 5.97436C7.48549 7.20372 5.50862 7.20372 4.65236 5.97436C3.51486 4.53374 4.65236 2.24747 6.49922 2.30186H6.49987Z"
                fill="#E70071"
            />
        </g>
        <defs>
            <clipPath id="clip0_297_19">
                <rect
                    width="13"
                    height="13"
                    fill="white"
                    transform="matrix(-1 0 0 1 13 0)"
                />
            </clipPath>
        </defs>
    </svg>
);
