import { SVGProps } from 'react';

export default (props: SVGProps<SVGSVGElement>) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="SubWallet">
        <g clip-path="url(#clip0_3619_97329)">
            <mask id="mask0_3619_97329" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                <path
                    d="M0 6.62069C0 2.96418 2.96418 0 6.62069 0H17.3793C21.0358 0 24 2.96418 24 6.62069V17.3793C24 21.0358 21.0358 24 17.3793 24H6.62069C2.96418 24 0 21.0358 0 17.3793V6.62069Z"
                    fill="#0C110F"></path>
                <path
                    d="M0 6.62069C0 2.96418 2.96418 0 6.62069 0H17.3793C21.0358 0 24 2.96418 24 6.62069V17.3793C24 21.0358 21.0358 24 17.3793 24H6.62069C2.96418 24 0 21.0358 0 17.3793V6.62069Z"
                    fill="black" fill-opacity="0.2"></path>
            </mask>
            <g mask="url(#mask0_3619_97329)">
                <path
                    d="M0 6.62069C0 2.96418 2.96418 0 6.62069 0H17.3793C21.0358 0 24 2.96418 24 6.62069V17.3793C24 21.0358 21.0358 24 17.3793 24H6.62069C2.96418 24 0 21.0358 0 17.3793V6.62069Z"
                    fill="#0C110F"></path>
                <path
                    d="M0 6.62069C0 2.96418 2.96418 0 6.62069 0H17.3793C21.0358 0 24 2.96418 24 6.62069V17.3793C24 21.0358 21.0358 24 17.3793 24H6.62069C2.96418 24 0 21.0358 0 17.3793V6.62069Z"
                    fill="black" fill-opacity="0.2"></path>
                <g filter="url(#filter0_f_3619_97329)">
                    <ellipse cx="7.20078" cy="18" rx="8.4" ry="12" fill="#1A337A"></ellipse>
                </g>
                <g filter="url(#filter1_f_3619_97329)">
                    <ellipse cx="18.0016" cy="8.39999" rx="8.4" ry="12" fill="#1F503D"></ellipse>
                </g>
            </g>
            <path
                d="M16.7992 9.84198V7.67719L8.7978 4.2L7.19922 5.07946L7.20765 11.8174L13.1939 14.4286L9.99675 15.9034V14.7629L8.5289 14.1174L7.20765 14.7939V18.9205L8.79918 19.8L16.7992 15.8898V13.1162L9.59921 9.99418V8.10002L15.3117 10.5726L16.7992 9.84198Z"
                fill="white"></path>
        </g>
        <defs>
            <filter id="filter0_f_3619_97329" x="-11.1992" y="-4" width="36.8008" height="44"
                    filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
                <feGaussianBlur stdDeviation="5" result="effect1_foregroundBlur_3619_97329"></feGaussianBlur>
            </filter>
            <filter id="filter1_f_3619_97329" x="-0.398438" y="-13.6" width="36.8008" height="44"
                    filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
                <feGaussianBlur stdDeviation="5" result="effect1_foregroundBlur_3619_97329"></feGaussianBlur>
            </filter>
            <clipPath id="clip0_3619_97329">
                <rect width="24" height="24" fill="white"></rect>
            </clipPath>
        </defs>
    </svg>
);
