import { SVGProps } from 'react';

export default (props: SVGProps<SVGSVGElement>) => (
    <svg
        {...props}
        viewBox="0 0 14 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M7.01523 0C6.90086 0.00154078 6.79 0.0378336 6.69864 0.103745L3.51395 2.39176C3.37968 2.48923 3.30082 2.64131 3.30082 2.80263C3.30082 2.96396 3.37967 3.11604 3.51395 3.2135L6.69864 5.50152C6.81295 5.58398 6.95682 5.61953 7.09849 5.60025C7.24016 5.58096 7.36806 5.50849 7.45382 5.39881C7.5403 5.2893 7.57755 5.15132 7.5574 5.01544C7.53732 4.87951 7.46139 4.75688 7.34654 4.67477L5.4502 3.31627H10.0012C11.6269 3.31627 12.923 4.5672 12.923 6.14409C12.923 7.72097 11.627 8.96883 10.0012 8.96883H0.536394C0.393593 8.96934 0.256817 9.02418 0.156209 9.12126C0.0556658 9.21832 -0.000530238 9.34969 3.77134e-06 9.48642C0.000540161 9.62252 0.0572786 9.75279 0.157704 9.849C0.258188 9.94522 0.394316 9.99949 0.536394 10H10.0012C12.2098 10 14 8.2696 14 6.14403C14 4.01846 12.21 2.28908 10.0012 2.28908L5.4502 2.28913L7.34654 0.92966V0.929603C7.46109 0.847486 7.53679 0.725081 7.55693 0.589439C7.57701 0.453737 7.53994 0.315978 7.45382 0.206588C7.36824 0.0970792 7.24075 0.0246652 7.09939 0.00514792C7.0715 0.00138164 7.04337 -0.000330355 7.01524 0.000126168L7.01523 0Z"
            fill="#E6007A"
        />
    </svg>
);
