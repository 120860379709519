import { SVGProps } from 'react';

export default (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="156"
        height="52"
        viewBox="0 0 156 52"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_186_7)">
            <path
                d="M21.1758 10.0839C25.6609 10.0839 29.2968 7.93845 29.2968 5.29194C29.2968 2.64542 25.6609 0.5 21.1758 0.5C16.6906 0.5 13.0547 2.64542 13.0547 5.29194C13.0547 7.93845 16.6906 10.0839 21.1758 10.0839Z"
                fill="white"
            />
            <path
                d="M21.1102 36.6776C28.047 36.6776 33.6696 43.448 33.6696 47.5405C33.6696 51.6329 28.047 51.4987 21.1102 51.4987C14.1735 51.4987 8.55078 51.6329 8.55078 47.5405C8.55078 43.448 14.1735 36.6776 21.1102 36.6776Z"
                fill="white"
            />
            <path
                d="M9.85923 16.7171C12.1018 12.7764 12.0874 8.50851 9.82706 7.18454C7.56673 5.86057 3.91639 7.98185 1.67382 11.9225C-0.568753 15.8632 -0.554353 20.1311 1.70598 21.4551C3.96632 22.779 7.61665 20.6578 9.85923 16.7171Z"
                fill="white"
            />
            <path
                d="M40.6185 34.7068C42.8611 30.7662 42.8477 26.4989 40.5885 25.1756C38.3294 23.8524 34.6801 25.9742 32.4375 29.9149C30.1949 33.8556 30.2083 38.1229 32.4675 39.4461C34.7266 40.7694 38.3759 38.6475 40.6185 34.7068Z"
                fill="white"
            />
            <path
                d="M9.82706 39.5051C12.0874 38.1811 12.1018 33.9133 9.85923 29.9726C7.61665 26.0319 3.96632 23.9106 1.70598 25.2346C-0.554353 26.5585 -0.568754 30.8264 1.67382 34.7671C3.91639 38.7078 7.56672 40.8291 9.82706 39.5051Z"
                fill="white"
            />
            <path
                d="M40.6471 21.456C42.9062 20.1327 42.9197 15.8655 40.6771 11.9248C38.4345 7.98408 34.7852 5.86223 32.526 7.18548C30.2669 8.50874 30.2535 12.776 32.4961 16.7167C34.7387 20.6574 38.388 22.7793 40.6471 21.456Z"
                fill="white"
            />
            <path
                d="M21.1123 34.0668C27.0532 34.0668 31.8692 29.1808 31.8692 23.1536C31.8692 17.1264 27.0532 12.2404 21.1123 12.2404C15.1715 12.2404 10.3555 17.1264 10.3555 23.1536C10.3555 29.1808 15.1715 34.0668 21.1123 34.0668Z"
                fill="white"
            />
            <path
                d="M69.973 13.4407C69.973 10.271 67.6413 8.15833 63.8822 8.15833H59.0597C58.2468 8.15833 57.5879 8.82685 57.5879 9.65153V22.7445C57.5879 23.5692 58.2468 24.2377 59.0597 24.2377C59.8726 24.2377 60.5315 23.5692 60.5315 22.7445V20.4021C60.5315 19.5774 61.1905 18.9089 62.0033 18.9089H63.8822C67.4594 18.9089 69.973 16.8646 69.973 13.442V13.4407ZM63.8606 15.9664H62.0046C61.1917 15.9664 60.5328 15.2978 60.5328 14.4732V12.5902C60.5328 11.7655 61.1917 11.097 62.0046 11.097H63.8606C65.8298 11.097 66.962 11.9475 66.962 13.5323C66.962 15.1172 65.8756 15.9676 63.8606 15.9676V15.9664Z"
                fill="white"
            />
            <path
                d="M87.3606 16.1961C87.3606 10.7989 84.6205 7.92731 79.4596 7.92731C74.2987 7.92731 71.5586 10.7989 71.5586 16.1961C71.5586 21.5933 74.3203 24.4648 79.4596 24.4648C84.5989 24.4648 87.3606 21.5714 87.3606 16.1961ZM79.4583 21.5249C76.0402 21.5249 74.5684 19.9401 74.5684 16.1961C74.5684 12.4521 76.0173 10.8673 79.4583 10.8673C82.8994 10.8673 84.3483 12.4289 84.3483 16.1961C84.3483 19.9633 82.8765 21.5249 79.4583 21.5249Z"
                fill="white"
            />
            <path
                d="M91.3781 24.2351H98.1928C98.9738 24.2351 99.6074 23.5924 99.6074 22.8C99.6074 22.0076 98.9738 21.3649 98.1928 21.3649H94.3218C93.5407 21.3649 92.9072 20.7222 92.9072 19.9297V9.59345C92.9072 8.80104 92.2737 8.15833 91.4926 8.15833H91.3794C90.5983 8.15833 89.9648 8.80104 89.9648 9.59345V22.8013C89.9648 23.5937 90.5983 24.2364 91.3794 24.2364L91.3781 24.2351Z"
                fill="white"
            />
            <path
                d="M103.079 24.2351H103.158C103.949 24.2351 104.59 23.5847 104.59 22.7819V21.2823C104.59 20.0085 106.09 19.3515 107.005 20.224L110.788 23.8389C111.054 24.0931 111.405 24.2338 111.77 24.2338H111.809C113.12 24.2338 113.742 22.5961 112.77 21.7043L107.588 16.9472C106.98 16.3884 106.957 15.4269 107.54 14.8397L111.705 10.6388C112.611 9.72508 111.973 8.15573 110.695 8.15573C110.309 8.15573 109.939 8.31318 109.67 8.59324L107.047 11.3202C106.15 12.2533 104.59 11.608 104.59 10.3058V9.60893C104.59 8.80619 103.949 8.15573 103.158 8.15573H103.079C102.288 8.15573 101.646 8.80619 101.646 9.60893V22.7819C101.646 23.5847 102.288 24.2351 103.079 24.2351Z"
                fill="white"
            />
            <path
                d="M131.748 23.2104L127.11 8.69909C127.007 8.37644 126.71 8.15704 126.376 8.15704H122.291C121.957 8.15704 121.66 8.37515 121.557 8.69909L116.919 23.2104C116.757 23.7163 117.129 24.2351 117.653 24.2351H119.082C119.419 24.2351 119.716 24.0132 119.818 23.6879L120.677 20.9248C120.779 20.5983 121.076 20.3776 121.413 20.3776H127.255C127.592 20.3776 127.89 20.5996 127.991 20.9248L128.851 23.6879C128.953 24.0144 129.251 24.2351 129.588 24.2351H131.016C131.541 24.2351 131.913 23.7163 131.75 23.2104H131.748ZM125.859 17.506H122.804C122.282 17.506 121.911 16.9924 122.067 16.4865L123.595 11.5758C123.697 11.2493 123.994 11.0286 124.332 11.0286C124.669 11.0286 124.966 11.2506 125.068 11.5758L126.596 16.4865C126.752 16.9911 126.381 17.506 125.859 17.506Z"
                fill="white"
            />
            <path
                d="M59.0617 27.9946C58.2488 27.9946 57.5898 28.6631 57.5898 29.4878V42.5808C57.5898 43.4055 58.2488 44.074 59.0617 44.074C59.8745 44.074 60.5335 43.4055 60.5335 42.5808V29.4878C60.5335 28.6631 59.8745 27.9946 59.0617 27.9946Z"
                fill="white"
            />
            <path
                d="M70.086 27.9946H64.6999C64.2737 27.9946 63.9277 28.3456 63.9277 28.778V43.2893C63.9277 43.7217 64.2737 44.0727 64.6999 44.0727H70.086C75.1794 44.0727 77.987 41.2244 77.987 36.0336C77.987 30.8429 75.2023 27.9946 70.086 27.9946ZM70.0631 41.1327H67.6435C67.2174 41.1327 66.8714 40.7817 66.8714 40.3494V31.7179C66.8714 31.2856 67.2174 30.9346 67.6435 30.9346H70.0631C73.5041 30.9346 74.9759 32.4729 74.9759 36.0336C74.9759 39.5944 73.4812 41.1327 70.0631 41.1327Z"
                fill="white"
            />
            <path
                d="M90.5193 41.2011H84.95C84.1689 41.2011 83.5354 40.5584 83.5354 39.766V38.8471C83.5354 38.0547 84.1689 37.412 84.95 37.412H88.9342C89.7153 37.412 90.3488 36.7693 90.3488 35.9768C90.3488 35.1844 89.7153 34.5417 88.9342 34.5417H84.95C84.1689 34.5417 83.5354 33.899 83.5354 33.1066V32.3025C83.5354 31.5101 84.1689 30.8674 84.95 30.8674H90.0664C90.8475 30.8674 91.481 30.2247 91.481 29.4323C91.481 28.6399 90.8475 27.9972 90.0664 27.9972H82.0064C81.2253 27.9972 80.5918 28.6399 80.5918 29.4323V42.6401C80.5918 43.4325 81.2253 44.0753 82.0064 44.0753H90.5193C91.3003 44.0753 91.9338 43.4325 91.9338 42.6401C91.9338 41.8477 91.3003 41.205 90.5193 41.205V41.2011Z"
                fill="white"
            />
            <path
                d="M106.061 27.9946C105.248 27.9946 104.589 28.6631 104.589 29.4878V33.5041C104.589 35.0038 102.656 35.5678 101.871 34.2965L98.4106 28.6954C98.141 28.2592 97.6703 27.9946 97.164 27.9946H96.0089C95.196 27.9946 94.5371 28.6631 94.5371 29.4878V42.5808C94.5371 43.4055 95.196 44.074 96.0089 44.074C96.8218 44.074 97.4807 43.4055 97.4807 42.5808V37.7592C97.4807 36.2595 99.4143 35.6955 100.199 36.9667L104.157 43.3732C104.426 43.8094 104.897 44.0727 105.403 44.0727H106.061C106.874 44.0727 107.533 43.4042 107.533 42.5795V29.4865C107.533 28.6618 106.874 27.9933 106.061 27.9933V27.9946Z"
                fill="white"
            />
            <path
                d="M120.766 27.9946H111.325C110.544 27.9946 109.91 28.6373 109.91 29.4297C109.91 30.2221 110.544 30.8649 111.325 30.8649H113.159C113.94 30.8649 114.574 31.5076 114.574 32.3V42.6363C114.574 43.4287 115.207 44.0714 115.988 44.0714H116.101C116.883 44.0714 117.516 43.4287 117.516 42.6363V32.3C117.516 31.5076 118.15 30.8649 118.931 30.8649H120.765C121.546 30.8649 122.18 30.2221 122.18 29.4297C122.18 28.6373 121.546 27.9946 120.765 27.9946H120.766Z"
                fill="white"
            />
            <path
                d="M126.028 27.9946C125.216 27.9946 124.557 28.6631 124.557 29.4878V42.5808C124.557 43.4055 125.216 44.074 126.028 44.074C126.841 44.074 127.5 43.4055 127.5 42.5808V29.4878C127.5 28.6631 126.841 27.9946 126.028 27.9946Z"
                fill="white"
            />
            <path
                d="M140.735 27.9946H131.293C130.512 27.9946 129.879 28.6373 129.879 29.4297C129.879 30.2221 130.512 30.8649 131.293 30.8649H133.128C133.909 30.8649 134.542 31.5076 134.542 32.3V42.6363C134.542 43.4287 135.176 44.0714 135.957 44.0714H136.07C136.851 44.0714 137.485 43.4287 137.485 42.6363V32.3C137.485 31.5076 138.118 30.8649 138.899 30.8649H140.734C141.515 30.8649 142.148 30.2221 142.148 29.4297C142.148 28.6373 141.515 27.9946 140.734 27.9946H140.735Z"
                fill="white"
            />
            <path
                d="M154.615 27.9946C154.142 27.9946 153.7 28.2411 153.446 28.6463L151.293 32.087C150.748 32.9595 149.493 32.9556 148.953 32.0806L146.835 28.6528C146.582 28.2437 146.139 27.9946 145.662 27.9946C144.567 27.9946 143.905 29.2245 144.498 30.1589L148.403 36.3059C148.547 36.5331 148.625 36.7964 148.625 37.0661V42.6672C148.625 43.4429 149.244 44.0714 150.009 44.0714H150.184C150.949 44.0714 151.568 43.4429 151.568 42.6672V37.0726C151.568 36.7977 151.647 36.5305 151.796 36.3008L155.772 30.1679C156.378 29.2348 155.718 27.992 154.616 27.992L154.615 27.9946Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_186_7">
                <rect
                    width="156"
                    height="51"
                    fill="white"
                    transform="translate(0 0.5)"
                />
            </clipPath>
        </defs>
    </svg>
);
