import { SVGProps } from 'react';

export default (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="193"
        height="147"
        viewBox="0 0 193 147"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M161 83.0004C152.513 83.0004 144.373 86.3719 138.373 92.3733C132.372 98.374 129 106.513 129 115C129 123.487 132.372 131.627 138.373 137.627C144.374 143.628 152.513 147 161 147C169.487 147 177.627 143.628 183.627 137.627C189.628 131.626 193 123.487 193 115C193 109.383 191.521 103.864 188.713 99.0001C185.904 94.1357 181.864 90.0958 177 87.2871C172.135 84.4786 166.617 83 161 83L161 83.0004ZM177.96 109.103L159.674 127.389C158.816 128.255 157.647 128.741 156.429 128.741C155.21 128.741 154.041 128.255 153.183 127.389L144.04 118.246C142.881 117.087 142.428 115.396 142.852 113.813C143.276 112.228 144.513 110.991 146.098 110.567C147.681 110.143 149.372 110.596 150.531 111.755L156.428 117.698L171.469 102.612C172.628 101.453 174.319 101 175.902 101.424C177.487 101.848 178.724 103.086 179.148 104.67C179.572 106.253 179.119 107.944 177.96 109.103Z"
            fill="#E70071"
        />
        <path
            d="M165 15.82V4.62577C165 3.39923 164.517 2.22261 163.657 1.35519C162.798 0.487146 161.632 0 160.417 0H4.58337C3.36807 0 2.20223 0.487146 1.34277 1.35519C0.482679 2.22252 0 3.39921 0 4.62577V15.82L82.5 55L165 15.82Z"
            fill="#E70071"
        />
        <path
            d="M160.417 73.3446C161.953 73.396 163.484 73.5486 165 73.8012V26L84.4708 63.8026C83.2147 64.3519 81.7854 64.3519 80.5292 63.8026L0 26.0457V114.434C0 115.645 0.482681 116.806 1.34277 117.662C2.20215 118.519 3.36805 119 4.58337 119H119.625C119.379 117.489 119.226 115.964 119.167 114.434C119.167 103.537 123.513 93.086 131.249 85.3794C138.984 77.6737 149.477 73.3443 160.417 73.3443L160.417 73.3446Z"
            fill="#E70071"
        />
    </svg>
);
