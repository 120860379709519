import { SVGProps } from 'react';

export default (props: SVGProps<SVGSVGElement>) => (
    <svg
        {...props}
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M7.482 0.375C4.87275 0.375 2.75 2.49775 2.75 5.107C2.75 5.28923 2.89765 5.43689 3.07989 5.43689C3.26212 5.43689 3.40977 5.28924 3.40977 5.107C3.40977 2.86163 5.23615 1.03525 7.48152 1.03525C9.7269 1.03525 11.5533 2.86163 11.5533 5.107V7.25213C11.5533 7.43435 11.7009 7.58201 11.8832 7.58201C12.0654 7.58201 12.213 7.43436 12.213 7.25213L12.2142 5.107C12.2142 2.49775 10.0915 0.375 7.48222 0.375H7.482Z"
            fill="#E6007A"
        />
        <path
            d="M11.9205 8.05901C11.4758 8.05901 11.1137 7.69689 11.1137 7.25217V5.10704C11.1137 3.10479 9.48478 1.47542 7.48202 1.47542C5.47977 1.47542 3.8504 3.10429 3.8504 5.10704C3.8504 5.53184 3.5047 5.87756 3.07989 5.87756C2.89766 5.87756 2.75 6.02521 2.75 6.20744C2.75 6.38968 2.89765 6.53733 3.07989 6.53733C3.86856 6.53733 4.51014 5.89573 4.51014 5.10708C4.51014 3.46883 5.84314 2.13571 7.48151 2.13571C9.11989 2.13571 10.4529 3.46871 10.4529 5.10708V7.25221C10.4529 8.06138 11.1115 8.71946 11.9201 8.71946C12.1024 8.71946 12.25 8.57181 12.25 8.38957C12.2506 8.20676 12.103 8.05909 11.9207 8.05909L11.9205 8.05901Z"
            fill="#E6007A"
        />
        <path
            d="M11.9213 9.15925C10.8696 9.15925 10.0141 8.30377 10.0141 7.252V5.10687C10.0141 3.71112 8.87854 2.57625 7.48347 2.57625C6.08772 2.57625 4.95284 3.7118 4.95284 5.10687C4.95284 6.13812 4.11378 6.97787 3.08184 6.97787C2.89962 6.97787 2.75195 7.12552 2.75195 7.30776C2.75195 7.48999 2.8996 7.63765 3.08184 7.63765C4.47759 7.63765 5.61247 6.5021 5.61247 5.10702C5.61247 4.07577 6.45153 3.23602 7.48347 3.23602C8.51472 3.23602 9.35447 4.07509 9.35447 5.10702V7.25215C9.35447 8.66777 10.5064 9.81965 11.922 9.81965C12.1042 9.81965 12.2519 9.672 12.2519 9.48976C12.2513 9.30695 12.1036 9.15929 11.9214 9.15929L11.9213 9.15925Z"
            fill="#E6007A"
        />
        <path
            d="M8.91176 5.10699C8.91176 4.31832 8.27016 3.67674 7.48151 3.67674C6.69286 3.67674 6.05126 4.31834 6.05126 5.10699C6.05126 6.74524 4.71826 8.07837 3.07989 8.07837C2.89766 8.07837 2.75 8.22602 2.75 8.40825C2.75 8.59049 2.89765 8.73814 3.07989 8.73814C5.08214 8.73814 6.71151 7.10927 6.71151 5.10652C6.71151 4.68172 7.05721 4.336 7.48203 4.336C7.90684 4.336 8.25254 4.6817 8.25254 5.10652C8.25254 7.95827 5.93216 10.2785 3.08054 10.2785C2.89831 10.2785 2.75065 10.4262 2.75065 10.6084C2.75065 10.7906 2.8983 10.9383 3.08054 10.9383C6.29566 10.9389 8.91179 8.32254 8.91179 5.10704L8.91176 5.10699Z"
            fill="#E6007A"
        />
        <path
            d="M7.81141 5.107C7.81141 4.92478 7.66376 4.77711 7.48153 4.77711C7.29929 4.77711 7.15164 4.92476 7.15164 5.107C7.15164 7.35238 5.32526 9.17875 3.07989 9.17875C2.89766 9.17875 2.75 9.3264 2.75 9.50864C2.75 9.69088 2.89765 9.83853 3.07989 9.83853C5.68851 9.83853 7.81139 7.71578 7.81139 5.10703L7.81141 5.107Z"
            fill="#E6007A"
        />
        <path
            d="M12.2489 10.5903C12.2489 10.408 12.1012 10.2604 11.919 10.2604C10.7084 10.2604 9.62098 9.53849 9.14748 8.42161C9.076 8.25345 8.88263 8.17551 8.71506 8.24641C8.54689 8.31731 8.46896 8.51126 8.53986 8.67884C9.11642 10.0406 10.4431 10.9206 11.9195 10.9206C12.1011 10.92 12.2488 10.7724 12.2488 10.5901L12.2489 10.5903Z"
            fill="#E6007A"
        />
        <path
            d="M10.2175 13.6738C10.2732 13.4997 10.1771 13.3146 10.0031 13.2589C9.28884 13.031 8.63322 12.687 8.05372 12.2364C7.94298 12.1503 7.78946 12.1433 7.67227 12.2194C6.88301 12.7286 6.01877 13.1019 5.10415 13.3292C4.9272 13.3732 4.81939 13.5519 4.86332 13.7288C4.90024 13.8794 5.03559 13.9796 5.18384 13.9796C5.2102 13.9796 5.23657 13.9761 5.26352 13.9702C6.17407 13.7446 7.03777 13.3837 7.83465 12.8974C8.42879 13.3274 9.08977 13.6608 9.80277 13.8882C9.9768 13.9439 10.1625 13.8472 10.2176 13.6737L10.2175 13.6738Z"
            fill="#E6007A"
        />
        <path
            d="M10.9541 12.3697C10.0325 12.1957 9.17352 11.7756 8.4709 11.1533L8.34727 11.0373C8.33555 11.0268 8.32324 11.0162 8.31035 11.0075C8.09473 10.8551 7.80586 10.8334 7.57031 10.9524C7.55391 10.9606 7.53809 10.9705 7.52344 10.9817L7.36465 11.0994C6.37208 11.8078 5.26115 12.2491 4.0634 12.4102C3.88293 12.4348 3.75579 12.6006 3.78039 12.7817C3.80265 12.9475 3.94445 13.0676 4.10734 13.0676C4.12199 13.0676 4.13664 13.0664 4.15187 13.0647C5.45737 12.8889 6.66737 12.4084 7.75312 11.6332L7.88437 11.5359C7.89375 11.5342 7.90429 11.5342 7.91777 11.54L8.03378 11.649C8.8248 12.3492 9.79166 12.8232 10.831 13.0189C11.0109 13.0523 11.1832 12.9345 11.2172 12.7558C11.2512 12.5759 11.1334 12.4037 10.9541 12.3697L10.9541 12.3697Z"
            fill="#E6007A"
        />
        <path
            d="M11.8571 11.7017C11.8735 11.5201 11.7387 11.3601 11.5571 11.3437C10.6776 11.2658 9.86373 10.916 9.20985 10.3383C9.16005 10.2861 9.112 10.2404 9.06337 10.2C9.05809 10.1947 9.05223 10.19 9.04637 10.1853C9.03993 10.1801 9.03231 10.1765 9.02528 10.1713C9.02001 10.1678 9.01473 10.1637 9.00887 10.1601C8.43582 9.71775 7.6085 9.66034 6.97925 10.0254C6.9728 10.0283 6.96577 10.0318 6.95932 10.0353C6.95405 10.0383 6.94819 10.0406 6.94292 10.0435C6.93472 10.0488 6.92651 10.0541 6.9189 10.0599L6.70913 10.2158C5.74643 10.9031 4.61088 11.301 3.42551 11.3666C3.24328 11.3765 3.10383 11.5324 3.11378 11.714C3.12316 11.8898 3.26906 12.0258 3.44308 12.0258C3.44894 12.0258 3.45539 12.0258 3.46125 12.0252C4.769 11.9531 6.02237 11.5142 7.09112 10.7531C7.09112 10.7531 7.09171 10.7531 7.09229 10.7525C7.09288 10.7519 7.09346 10.7513 7.09464 10.7507C7.09581 10.7501 7.09639 10.7495 7.09757 10.749L7.29972 10.599C7.71163 10.3534 8.24835 10.3927 8.61922 10.6892C8.61922 10.6892 8.61922 10.6898 8.6198 10.6898C8.66668 10.7355 8.71355 10.7812 8.76278 10.8245C9.53035 11.5042 10.476 11.9115 11.4979 12.0011C11.6801 12.0181 11.8407 11.8833 11.8571 11.7017L11.8571 11.7017Z"
            fill="#E6007A"
        />
        <path
            d="M8.18927 13.9038C7.95607 13.9442 7.71759 13.9653 7.48028 13.9653C7.29805 13.9653 7.15039 14.1129 7.15039 14.2952C7.15039 14.4774 7.29804 14.6251 7.48028 14.6251C7.75567 14.6251 8.03223 14.601 8.30235 14.5542C8.48224 14.5225 8.60235 14.352 8.57072 14.1721C8.54025 13.9922 8.36798 13.8727 8.18927 13.9038Z"
            fill="#E6007A"
        />
    </svg>
);
