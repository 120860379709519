import { SVGProps } from 'react';

export default (props: SVGProps<SVGSVGElement>) => (
    <svg
        {...props}
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14 7C14 10.8659 10.8659 14 7 14C3.13413 14 0 10.8659 0 7C0 3.13413 3.13413 0 7 0C10.8659 0 14 3.13413 14 7ZM7 5.48403C7.41398 5.48403 7.74977 5.81981 7.74977 6.23434V10.2151C7.74977 10.6297 7.41399 10.9654 7 10.9654C6.58601 10.9654 6.25023 10.6297 6.25023 10.2151V6.23434C6.25023 5.81981 6.58601 5.48403 7 5.48403ZM7.74977 3.9998C7.74977 3.58582 7.41399 3.25003 7 3.25003C6.58601 3.25003 6.25023 3.58581 6.25023 3.9998V4.01347C6.25023 4.42745 6.58601 4.76324 7 4.76324C7.41399 4.76324 7.74977 4.42746 7.74977 4.01347V3.9998Z"
            fill="#A3A3A3"
        />
    </svg>
);
