import { SVGProps } from 'react';

export default (props: SVGProps<SVGSVGElement>) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="Nova">
        <g clip-path="url(#clip0_3619_97357)">
            <path
                d="M6.66667 0H17.3333C21.0148 0 24 2.98519 24 6.66667V17.3333C24 21.0148 21.0148 24 17.3333 24H6.66667C2.98518 24 0 21.0148 0 17.3333V6.66667C0 2.98519 2.98518 0 6.66667 0Z"
                fill="url(#paint0_linear_3619_97357)"></path>
            <mask id="mask0_3619_97357" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                <path
                    d="M6.66667 0H17.3333C21.0148 0 24 2.98519 24 6.66667V17.3333C24 21.0148 21.0148 24 17.3333 24H6.66667C2.98518 24 0 21.0148 0 17.3333V6.66667C0 2.98519 2.98518 0 6.66667 0Z"
                    fill="#295CE9"></path>
            </mask>
            <g mask="url(#mask0_3619_97357)">
                <g filter="url(#filter0_f_3619_97357)">
                    <circle cx="0.100695" cy="37.1994" r="29.3741" fill="url(#paint1_radial_3619_97357)"></circle>
                </g>
            </g>
            <path
                d="M20.3696 11.9999V12.2221C19.0067 12.4369 16.0734 12.948 14.6289 13.4962C14.1104 13.6962 13.703 14.0962 13.503 14.6147C12.9474 16.0517 12.4363 18.9999 12.2215 20.3703H11.7771C11.5622 18.9999 11.0437 16.0443 10.4956 14.6147C10.2956 14.1036 9.88076 13.6962 9.36965 13.4962C7.9252 12.9406 4.99187 12.4369 3.62891 12.2221V11.7777C4.99187 11.5628 7.9252 11.0517 9.36965 10.5036C9.88816 10.3036 10.2956 9.90359 10.4956 9.38507C11.0511 7.94803 11.5622 4.99988 11.7771 3.62951H12.2215C12.4363 4.99988 12.9548 7.95544 13.503 9.38507C13.703 9.89618 14.1178 10.3036 14.6289 10.5036C16.0734 11.0591 19.0067 11.5628 20.3696 11.7777V11.9999Z"
                fill="white"></path>
        </g>
        <defs>
            <filter id="filter0_f_3619_97357" x="-33.2734" y="3.82524" width="66.748" height="66.7483"
                    filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
                <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_3619_97357"></feGaussianBlur>
            </filter>
            <linearGradient id="paint0_linear_3619_97357" x1="24" y1="7.15338e-7" x2="-0.0000035761" y2="23.9999"
                            gradientUnits="userSpaceOnUse">
                <stop stop-color="#0D7FD4"></stop>
                <stop offset="0.5625" stop-color="#395FEA"></stop>
            </linearGradient>
            <radialGradient id="paint1_radial_3619_97357" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                            gradientTransform="translate(0.100695 37.1994) rotate(90) scale(29.3741)">
                <stop offset="0.21875" stop-color="#E1E1F2"></stop>
                <stop offset="0.473958" stop-color="#B6B7EA"></stop>
                <stop offset="1" stop-color="#576CEB" stop-opacity="0.47"></stop>
            </radialGradient>
            <clipPath id="clip0_3619_97357">
                <rect width="24" height="24" fill="white"></rect>
            </clipPath>
        </defs>
    </svg>
);
