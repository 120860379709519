import { SVGProps } from 'react';

export default (props: SVGProps<SVGSVGElement>) => (
    <svg
        {...props}
        viewBox="0 0 14 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M13.5816 16.6149V1.30963H12.4805V0.942307H14.009V17.0003H12.4805V16.6329L13.5816 16.6149Z"
            fill="#E6007A"
        />
        <path
            d="M4.09385 6.17696V6.94975H4.11597C4.30954 6.66673 4.56496 6.43288 4.86513 6.26227C5.15675 6.10018 5.49362 6.01889 5.86568 6.01889C6.22769 6.01889 6.55953 6.08914 6.85618 6.22965C7.15534 6.36966 7.38411 6.61655 7.53997 6.97233C7.70992 6.72143 7.9402 6.50013 8.23383 6.30994C8.52544 6.11775 8.87237 6.0219 9.26958 6.0219C9.57226 6.0219 9.85281 6.05904 10.1092 6.1323C10.3677 6.20657 10.5889 6.32449 10.7729 6.48708C10.9569 6.64917 11.1012 6.86143 11.2048 7.12438C11.3079 7.38633 11.3597 7.70146 11.3597 8.0728V11.9016H9.78594V8.65992C9.78594 8.46773 9.7784 8.28708 9.76382 8.11797C9.75331 7.96391 9.71203 7.81337 9.64214 7.67537C9.57527 7.54941 9.47321 7.44554 9.34851 7.37629C9.2193 7.30303 9.04382 7.26589 8.82058 7.26589C8.59936 7.26589 8.42036 7.30855 8.2826 7.39285C8.14584 7.47816 8.03874 7.58856 7.96131 7.72505C7.88137 7.86907 7.82858 8.02714 7.80645 8.19023C7.78031 8.36436 7.76724 8.53999 7.76724 8.71713V11.9036H6.1935V8.69204C6.1935 8.52243 6.18998 8.35432 6.18294 8.19023C6.17721 8.03266 6.14528 7.8776 6.08842 7.73057C6.03412 7.59157 5.93758 7.47364 5.81188 7.39285C5.68216 7.30855 5.49211 7.26589 5.2387 7.26589C5.13915 7.27006 5.0406 7.28697 4.94507 7.31607C4.81535 7.35346 4.69468 7.41744 4.5906 7.50375C4.47597 7.59608 4.37843 7.72906 4.29697 7.90219C4.21602 8.07582 4.1753 8.30263 4.1753 8.58465V11.9066H2.60156V6.18599L4.09385 6.17696Z"
            fill="#E6007A"
        />
        <path
            d="M0.427372 1.32544V16.6307H1.52848V16.998H0V0.940048H1.52346V1.30737L0.427372 1.32544Z"
            fill="#E6007A"
        />
    </svg>
);
