import { SVGProps } from 'react';

export default (props: SVGProps<SVGSVGElement>) => (
    <svg
        {...props}
        viewBox="0 0 15 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12.6981 0.994735C11.7421 0.534315 10.7169 0.195098 9.64494 0.000814982C9.62541 -0.00293463 9.60592 0.00643606 9.59586 0.0251775C9.46401 0.27131 9.31796 0.592411 9.21568 0.844794C8.06275 0.663633 6.91574 0.663633 5.78645 0.844794C5.68415 0.586801 5.5328 0.27131 5.40036 0.0251775C5.3903 0.00706137 5.3708 -0.00230932 5.35128 0.000814982C4.27994 0.194478 3.25473 0.533694 2.29808 0.994735C2.2898 0.998482 2.2827 1.00473 2.27799 1.01285C0.33337 4.06208 -0.199344 7.03635 0.0619876 9.97375C0.06317 9.98812 0.0708562 10.0019 0.0814988 10.0106C1.36451 10.9995 2.60732 11.5999 3.82705 11.9978C3.84657 12.0041 3.86725 11.9966 3.87968 11.9797C4.1682 11.5662 4.4254 11.1301 4.64592 10.6715C4.65894 10.6447 4.64651 10.6128 4.61992 10.6022C4.21196 10.4398 3.8235 10.2417 3.44983 10.0169C3.42027 9.99874 3.41791 9.95437 3.4451 9.93313C3.52373 9.87129 3.60239 9.80694 3.67747 9.74197C3.69106 9.73011 3.70999 9.7276 3.72596 9.7351C6.1808 10.9114 8.83846 10.9114 11.2643 9.7351C11.2803 9.72698 11.2992 9.72949 11.3134 9.74135C11.3885 9.80632 11.4672 9.87129 11.5464 9.93313C11.5736 9.95437 11.5718 9.99874 11.5422 10.0169C11.1686 10.2461 10.7801 10.4398 10.3716 10.6016C10.345 10.6122 10.3331 10.6447 10.3462 10.6715C10.5714 11.1295 10.8286 11.5655 11.1118 11.9791C11.1236 11.9966 11.1449 12.0041 11.1644 11.9978C12.3901 11.5999 13.6329 10.9995 14.9159 10.0106C14.9271 10.0019 14.9342 9.98874 14.9354 9.97438C15.2482 6.57842 14.4116 3.62853 12.7176 1.01347C12.7135 1.00473 12.7064 0.998482 12.6981 0.994735ZM5.01251 8.18518C4.27344 8.18518 3.66446 7.47302 3.66446 6.59842C3.66446 5.72381 4.26163 5.01165 5.01251 5.01165C5.7693 5.01165 6.37238 5.73006 6.36055 6.59842C6.36055 7.47302 5.76338 8.18518 5.01251 8.18518ZM9.99672 8.18518C9.25766 8.18518 8.64868 7.47302 8.64868 6.59842C8.64868 5.72381 9.24583 5.01165 9.99672 5.01165C10.7535 5.01165 11.3566 5.73006 11.3448 6.59842C11.3448 7.47302 10.7535 8.18518 9.99672 8.18518Z"
            fill="#E6007A"
        />
    </svg>
);
