import { SVGProps } from 'react';

export default (props: SVGProps<SVGSVGElement>) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_3619_97324)">
            <path
                d="M0 6.62069C0 2.96418 2.96418 0 6.62069 0H17.3793C21.0358 0 24 2.96418 24 6.62069V17.3793C24 21.0358 21.0358 24 17.3793 24H6.62069C2.96418 24 0 21.0358 0 17.3793V6.62069Z"
                fill="#FF8C00"></path>
            <path
                d="M5.92383 9.88182C5.92383 6.51754 8.66033 3.79713 12.0085 3.79713C15.3567 3.79713 18.0932 6.51754 18.0932 9.88182C18.0932 13.0851 15.6143 15.709 12.4753 15.9504C12.4753 15.9504 11.7188 15.9987 11.5256 16.0309C10.6564 16.1436 9.9159 16.8035 9.72273 17.705C9.72273 17.705 9.46518 18.8801 9.44908 18.9767C9.3525 19.4274 8.90178 19.7171 8.45107 19.6205C8.00035 19.524 7.7106 19.0732 7.80718 18.6225C7.82328 18.5259 9.72273 9.67256 9.72273 9.67256C9.81931 9.22184 10.27 8.93209 10.7207 9.02868C11.1715 9.12526 11.4612 9.57598 11.3646 10.0267L10.5759 13.6646L10.431 14.3729C10.4149 14.4212 10.4632 14.4695 10.5115 14.4534C10.565 14.4457 10.6294 14.4345 10.7047 14.4213C10.7879 14.4067 10.8845 14.3898 10.9944 14.3729C11.4129 14.3085 12.2661 14.2602 12.2661 14.2602C14.5679 14.1314 16.403 12.2159 16.403 9.88182C16.403 7.38678 14.3104 5.37464 11.7832 5.50342C9.51347 5.6161 7.67841 7.48336 7.61402 9.75304C7.59792 10.2842 7.67841 10.8154 7.85547 11.3145C8.00035 11.7652 7.75889 12.2481 7.32427 12.393C6.87355 12.5378 6.39064 12.2964 6.24577 11.8618C6.03651 11.2179 5.92383 10.5579 5.92383 9.88182Z"
                fill="white"></path>
            <path
                d="M11.2359 18.5903C11.3486 18.0269 11.8959 17.6888 12.4432 17.8015C13.0066 17.9142 13.3446 18.4454 13.2319 19.0088C13.1193 19.5722 12.572 19.9102 12.0247 19.7975C11.4613 19.6849 11.1232 19.1376 11.2359 18.5903Z"
                fill="white"></path>
        </g>
        <defs>
            <clipPath id="clip0_3619_97324">
                <rect width="24" height="24" fill="white"></rect>
            </clipPath>
        </defs>
    </svg>
);
