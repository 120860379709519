import { SVGProps } from 'react';

    export default (props: SVGProps<SVGSVGElement>) => (
<svg width="97" height="97" viewBox="0 0 97 97" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M44.7689 22.3846C44.7689 20.3241 46.4392 18.6538 48.4997 18.6538C50.5602 18.6538 52.2305 20.3241 52.2305 22.3846L52.2305 26.1154C52.2305 28.1759 50.5602 29.8461 48.4997 29.8461C46.4392 29.8461 44.7689 28.1759 44.7689 26.1154L44.7689 22.3846Z" fill="url(#paint0_linear_609_8)"/>
<path d="M44.7689 41.0384C44.7689 38.978 46.4392 37.3077 48.4997 37.3077C50.5602 37.3077 52.2305 38.978 52.2305 41.0384L52.2305 74.6154C52.2305 76.6758 50.5602 78.3461 48.4997 78.3461C46.4392 78.3461 44.7689 76.6758 44.7689 74.6154L44.7689 41.0384Z" fill="url(#paint1_linear_609_8)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M97 48.5C97 21.7141 75.2859 -1.8983e-06 48.5 -4.24e-06C21.7141 -6.5817e-06 -4.75244e-07 21.7141 -2.81694e-06 48.5C-5.15864e-06 75.2859 21.7141 97 48.5 97C75.2859 97 97 75.2859 97 48.5ZM48.5 7.46153C71.1652 7.46154 89.5385 25.8348 89.5385 48.5C89.5385 71.1652 71.1652 89.5385 48.5 89.5385C25.8348 89.5385 7.46153 71.1652 7.46154 48.5C7.46154 25.8348 25.8348 7.46153 48.5 7.46153Z" fill="url(#paint2_linear_609_8)"/>
<defs>
<linearGradient id="paint0_linear_609_8" x1="52.2305" y1="29.8461" x2="43.7411" y2="29.003" gradientUnits="userSpaceOnUse">
<stop stop-color="#E6007A"/>
<stop offset="1" stop-color="#FF5EB3"/>
</linearGradient>
<linearGradient id="paint1_linear_609_8" x1="52.2305" y1="78.3461" x2="43.6637" y2="78.1141" gradientUnits="userSpaceOnUse">
<stop stop-color="#E6007A"/>
<stop offset="1" stop-color="#FF5EB3"/>
</linearGradient>
<linearGradient id="paint2_linear_609_8" x1="97" y1="97" x2="-12.0304" y2="80.7579" gradientUnits="userSpaceOnUse">
<stop stop-color="#E6007A"/>
<stop offset="1" stop-color="#FF5EB3"/>
</linearGradient>
</defs>
</svg>
);
