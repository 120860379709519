import { SVGProps } from 'react';

export default (props: SVGProps<SVGSVGElement>) => (
    <svg
        {...props}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 7.62939e-06C3.58 7.62939e-06 0 3.67056 0 8.20236C0 11.8319 2.29 14.8975 5.47 15.9843C5.87 16.0561 6.02 15.81 6.02 15.5947C6.02 15.3999 6.01 14.754 6.01 14.067C4 14.4464 3.48 13.5646 3.32 13.1033C3.23 12.8674 2.84 12.1395 2.5 11.9447C2.22 11.7909 1.82 11.4115 2.49 11.4013C3.12 11.391 3.57 11.9959 3.72 12.242C4.44 13.4826 5.59 13.134 6.05 12.9187C6.12 12.3856 6.33 12.0267 6.56 11.8216C4.78 11.6166 2.92 10.9091 2.92 7.77173C2.92 6.87973 3.23 6.14152 3.74 5.56735C3.66 5.36229 3.38 4.52155 3.82 3.39373C3.82 3.39373 4.49 3.17842 6.02 4.23447C6.66 4.04992 7.34 3.95764 8.02 3.95764C8.7 3.95764 9.38 4.04992 10.02 4.23447C11.55 3.16817 12.22 3.39373 12.22 3.39373C12.66 4.52155 12.38 5.36229 12.3 5.56735C12.81 6.14152 13.12 6.86948 13.12 7.77173C13.12 10.9194 11.25 11.6166 9.47 11.8216C9.76 12.078 10.01 12.5701 10.01 13.3391C10.01 14.4361 10 15.3179 10 15.5947C10 15.81 10.15 16.0664 10.55 15.9843C13.71 14.8975 16 11.8216 16 8.20236C16 3.67056 12.42 7.62939e-06 8 7.62939e-06Z"
            fill="#E6007A"
        />
    </svg>
);
