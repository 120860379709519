import back from './back';
import big_mail from './big_mail';
import discord from './discord';
import fingerprint from './fingerprint';
import github from './github';
import info from './info';
import info_outline from './info_outline';
import mail from './mail';
import matrix from './matrix';
import money from './money';
import open from './open';
import polkaid_logo from './polkaid_logo';
import polkaid_white_logo from './polkaid_white_logo';
import user_profile from './user_profile';
import web from './web';
import x from './x';
import polkadotjs from '@/Assets/svg/polkadotjs';
import mimir from '@/Assets/svg/mimir';
import subwallet from '@/Assets/svg/subwallet';
import novawallet from '@/Assets/svg/novawallet';
import talisman from '@/Assets/svg/talisman';
import signet from '@/Assets/svg/signet';
import social_section_solid from '@/Assets/svg/social_section_solid';
import kyc_section_solid from '@/Assets/svg/kyc_section_solid';
import basic_section_solid from '@/Assets/svg/basic_section_solid';
import social_section_gradient from '@/Assets/svg/social_section_gradient';
import kyc_section_gradient from '@/Assets/svg/kyc_section_gradient';
import basic_section_gradient from '@/Assets/svg/basic_section_gradient';

export default {
    money,
    info,
    discord,
    fingerprint,
    github,
    matrix,
    web,
    x,
    polkaid_white_logo,
    polkaid_logo,
    big_mail,
    info_outline,
    user_profile,
    mail,
    open,
    back,
    polkadotjs,
    mimir,
    subwallet,
    novawallet,
    talisman,
    signet,
    social_section_solid,
    kyc_section_solid,
    basic_section_solid,
    social_section_gradient,
    kyc_section_gradient,
    basic_section_gradient,
};
