import { SVGProps } from 'react';

export default (props: SVGProps<SVGSVGElement>) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="Signet">
        <g clip-path="url(#clip0_9919_48761)">
            <path
                d="M0 6.62069C0 2.96418 2.96418 0 6.62069 0H17.3793C21.0358 0 24 2.96418 24 6.62069V17.3793C24 21.0358 21.0358 24 17.3793 24H6.62069C2.96418 24 0 21.0358 0 17.3793V6.62069Z"
                fill="black"></path>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M19.2165 8.57832L20.0636 11.1855L16.7126 12.3698C15.5783 12.7707 15.1337 14.139 15.8158 15.13L17.8307 18.0578L15.6129 19.6691L13.451 16.8481C12.7193 15.8932 11.2806 15.8932 10.5489 16.8481L8.38703 19.6691L6.1692 18.0578L8.18414 15.13C8.86617 14.139 8.4216 12.7707 7.28732 12.3698L3.93628 11.1855L4.78341 8.57832L8.19055 9.58993C9.34385 9.93232 10.5077 9.08668 10.5385 7.88406L10.6293 4.33105H13.3706L13.4615 7.88406C13.4922 9.08668 14.6561 9.93232 15.8094 9.58993L19.2165 8.57832Z"
                  fill="#FD4848"></path>
        </g>
        <defs>
            <clipPath id="clip0_9919_48761">
                <rect width="24" height="24" fill="white"></rect>
            </clipPath>
        </defs>
    </svg>
);
