import { SVGProps } from 'react';

export default (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="156"
        height="52"
        viewBox="0 0 156 52"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M21.1748 10.0839C25.6599 10.0839 29.2959 7.93845 29.2959 5.29194C29.2959 2.64542 25.6599 0.5 21.1748 0.5C16.6896 0.5 13.0537 2.64542 13.0537 5.29194C13.0537 7.93845 16.6896 10.0839 21.1748 10.0839Z"
            fill="#E6007A"
        />
        <path
            d="M21.1092 36.6777C28.046 36.6777 33.6687 43.4481 33.6687 47.5405C33.6687 51.633 28.046 51.4987 21.1092 51.4987C14.1725 51.4987 8.5498 51.633 8.5498 47.5405C8.5498 43.4481 14.1725 36.6777 21.1092 36.6777Z"
            fill="#E6007A"
        />
        <path
            d="M9.85825 16.7171C12.1008 12.7764 12.0864 8.50851 9.82609 7.18454C7.56575 5.86057 3.91542 7.98185 1.67284 11.9225C-0.56973 15.8632 -0.555329 20.1311 1.70501 21.4551C3.96535 22.779 7.61568 20.6578 9.85825 16.7171Z"
            fill="#E6007A"
        />
        <path
            d="M40.6165 34.7068C42.8591 30.7662 42.8457 26.4989 40.5866 25.1756C38.3275 23.8524 34.6781 25.9742 32.4355 29.9149C30.193 33.8556 30.2064 38.1229 32.4655 39.4461C34.7246 40.7694 38.374 38.6475 40.6165 34.7068Z"
            fill="#E6007A"
        />
        <path
            d="M9.82609 39.5051C12.0864 38.1811 12.1008 33.9133 9.85825 29.9726C7.61568 26.0319 3.96535 23.9106 1.70501 25.2346C-0.555329 26.5585 -0.56973 30.8264 1.67284 34.7671C3.91542 38.7078 7.56575 40.8291 9.82609 39.5051Z"
            fill="#E6007A"
        />
        <path
            d="M40.6471 21.456C42.9062 20.1327 42.9197 15.8654 40.6771 11.9247C38.4345 7.98405 34.7852 5.8622 32.526 7.18545C30.2669 8.50871 30.2535 12.776 32.4961 16.7167C34.7387 20.6574 38.388 22.7792 40.6471 21.456Z"
            fill="#E6007A"
        />
        <path
            d="M21.1114 34.0668C27.0522 34.0668 31.8682 29.1808 31.8682 23.1536C31.8682 17.1264 27.0522 12.2404 21.1114 12.2404C15.1705 12.2404 10.3545 17.1264 10.3545 23.1536C10.3545 29.1808 15.1705 34.0668 21.1114 34.0668Z"
            fill="#E6007A"
        />
        <path
            d="M69.9721 13.4407C69.9721 10.271 67.6403 8.15833 63.8813 8.15833H59.0587C58.2459 8.15833 57.5869 8.82685 57.5869 9.65153V22.7445C57.5869 23.5692 58.2459 24.2377 59.0587 24.2377C59.8716 24.2377 60.5305 23.5692 60.5305 22.7445V20.4021C60.5305 19.5774 61.1895 18.9089 62.0024 18.9089H63.8813C67.4584 18.9089 69.9721 16.8646 69.9721 13.442V13.4407ZM63.8596 15.9664H62.0036C61.1908 15.9664 60.5318 15.2978 60.5318 14.4732V12.5902C60.5318 11.7655 61.1908 11.097 62.0036 11.097H63.8596C65.8288 11.097 66.961 11.9475 66.961 13.5323C66.961 15.1172 65.8746 15.9676 63.8596 15.9676V15.9664Z"
            fill="white"
        />
        <path
            d="M87.3606 16.1961C87.3606 10.7989 84.6205 7.92731 79.4596 7.92731C74.2987 7.92731 71.5586 10.7989 71.5586 16.1961C71.5586 21.5933 74.3203 24.4648 79.4596 24.4648C84.5989 24.4648 87.3606 21.5714 87.3606 16.1961ZM79.4583 21.5249C76.0402 21.5249 74.5684 19.9401 74.5684 16.1961C74.5684 12.4521 76.0173 10.8673 79.4583 10.8673C82.8994 10.8673 84.3483 12.4289 84.3483 16.1961C84.3483 19.9633 82.8765 21.5249 79.4583 21.5249Z"
            fill="white"
        />
        <path
            d="M91.3781 24.2351H98.1928C98.9738 24.2351 99.6074 23.5924 99.6074 22.8C99.6074 22.0076 98.9738 21.3649 98.1928 21.3649H94.3218C93.5407 21.3649 92.9072 20.7222 92.9072 19.9297V9.59345C92.9072 8.80104 92.2737 8.15833 91.4926 8.15833H91.3794C90.5983 8.15833 89.9648 8.80104 89.9648 9.59345V22.8013C89.9648 23.5937 90.5983 24.2364 91.3794 24.2364L91.3781 24.2351Z"
            fill="white"
        />
        <path
            d="M103.077 24.2351H103.156C103.947 24.2351 104.588 23.5847 104.588 22.7819V21.2823C104.588 20.0085 106.088 19.3516 107.003 20.224L110.786 23.8389C111.052 24.0932 111.403 24.2339 111.768 24.2339H111.807C113.118 24.2339 113.74 22.5961 112.768 21.7043L107.586 16.9472C106.978 16.3884 106.956 15.4269 107.538 14.8397L111.703 10.6388C112.609 9.72511 111.971 8.15576 110.693 8.15576C110.308 8.15576 109.937 8.31321 109.668 8.59327L107.045 11.3203C106.148 12.2534 104.588 11.6081 104.588 10.3059V9.60896C104.588 8.80622 103.947 8.15576 103.156 8.15576H103.077C102.286 8.15576 101.645 8.80622 101.645 9.60896V22.7819C101.645 23.5847 102.286 24.2351 103.077 24.2351Z"
            fill="white"
        />
        <path
            d="M131.747 23.2104L127.109 8.69909C127.006 8.37644 126.709 8.15704 126.375 8.15704H122.29C121.956 8.15704 121.659 8.37515 121.556 8.69909L116.918 23.2104C116.756 23.7163 117.128 24.2351 117.652 24.2351H119.081C119.418 24.2351 119.715 24.0132 119.817 23.6879L120.676 20.9248C120.778 20.5983 121.075 20.3776 121.412 20.3776H127.254C127.591 20.3776 127.889 20.5996 127.99 20.9248L128.85 23.6879C128.952 24.0144 129.25 24.2351 129.587 24.2351H131.015C131.54 24.2351 131.912 23.7163 131.749 23.2104H131.747ZM125.858 17.506H122.803C122.281 17.506 121.91 16.9924 122.066 16.4865L123.594 11.5758C123.696 11.2493 123.993 11.0286 124.331 11.0286C124.668 11.0286 124.965 11.2506 125.067 11.5758L126.595 16.4865C126.751 16.9911 126.38 17.506 125.858 17.506Z"
            fill="white"
        />
        <path
            d="M59.0597 27.9946C58.2468 27.9946 57.5879 28.6631 57.5879 29.4878V42.5807C57.5879 43.4054 58.2468 44.074 59.0597 44.074C59.8726 44.074 60.5315 43.4054 60.5315 42.5807V29.4878C60.5315 28.6631 59.8726 27.9946 59.0597 27.9946Z"
            fill="white"
        />
        <path
            d="M70.085 27.9946H64.6989C64.2728 27.9946 63.9268 28.3456 63.9268 28.778V43.2893C63.9268 43.7216 64.2728 44.0727 64.6989 44.0727H70.085C75.1785 44.0727 77.986 41.2243 77.986 36.0336C77.986 30.8429 75.2014 27.9946 70.085 27.9946ZM70.0621 41.1327H67.6426C67.2164 41.1327 66.8704 40.7817 66.8704 40.3493V31.7179C66.8704 31.2856 67.2164 30.9345 67.6426 30.9345H70.0621C73.5031 30.9345 74.9749 32.4729 74.9749 36.0336C74.9749 39.5943 73.4802 41.1327 70.0621 41.1327Z"
            fill="white"
        />
        <path
            d="M90.5173 41.2012H84.9481C84.167 41.2012 83.5335 40.5584 83.5335 39.766V38.8471C83.5335 38.0547 84.167 37.412 84.9481 37.412H88.9323C89.7133 37.412 90.3468 36.7693 90.3468 35.9769C90.3468 35.1845 89.7133 34.5417 88.9323 34.5417H84.9481C84.167 34.5417 83.5335 33.899 83.5335 33.1066V32.3026C83.5335 31.5102 84.167 30.8675 84.9481 30.8675H90.0644C90.8455 30.8675 91.479 30.2247 91.479 29.4323C91.479 28.6399 90.8455 27.9972 90.0644 27.9972H82.0044C81.2233 27.9972 80.5898 28.6399 80.5898 29.4323V42.6402C80.5898 43.4326 81.2233 44.0753 82.0044 44.0753H90.5173C91.2984 44.0753 91.9319 43.4326 91.9319 42.6402C91.9319 41.8477 91.2984 41.205 90.5173 41.205V41.2012Z"
            fill="white"
        />
        <path
            d="M106.06 27.9946C105.247 27.9946 104.588 28.6631 104.588 29.4878V33.5041C104.588 35.0037 102.655 35.5677 101.87 34.2965L98.4097 28.6954C98.14 28.2591 97.6693 27.9946 97.163 27.9946H96.0079C95.1951 27.9946 94.5361 28.6631 94.5361 29.4878V42.5808C94.5361 43.4054 95.1951 44.074 96.0079 44.074C96.8208 44.074 97.4798 43.4054 97.4798 42.5808V37.7591C97.4798 36.2595 99.4134 35.6955 100.198 36.9667L104.156 43.3732C104.425 43.8094 104.896 44.0727 105.402 44.0727H106.06C106.873 44.0727 107.532 43.4041 107.532 42.5795V29.4865C107.532 28.6618 106.873 27.9933 106.06 27.9933V27.9946Z"
            fill="white"
        />
        <path
            d="M120.765 27.9946H111.324C110.543 27.9946 109.909 28.6373 109.909 29.4297C109.909 30.2221 110.543 30.8648 111.324 30.8648H113.158C113.939 30.8648 114.573 31.5075 114.573 32.3V42.6362C114.573 43.4287 115.206 44.0714 115.987 44.0714H116.1C116.882 44.0714 117.515 43.4287 117.515 42.6362V32.3C117.515 31.5075 118.149 30.8648 118.93 30.8648H120.764C121.545 30.8648 122.179 30.2221 122.179 29.4297C122.179 28.6373 121.545 27.9946 120.764 27.9946H120.765Z"
            fill="white"
        />
        <path
            d="M126.028 27.9946C125.216 27.9946 124.557 28.6631 124.557 29.4878V42.5807C124.557 43.4054 125.216 44.074 126.028 44.074C126.841 44.074 127.5 43.4054 127.5 42.5807V29.4878C127.5 28.6631 126.841 27.9946 126.028 27.9946Z"
            fill="white"
        />
        <path
            d="M140.734 27.9946H131.293C130.511 27.9946 129.878 28.6373 129.878 29.4297C129.878 30.2221 130.511 30.8648 131.293 30.8648H133.127C133.908 30.8648 134.541 31.5075 134.541 32.3V42.6362C134.541 43.4287 135.175 44.0714 135.956 44.0714H136.069C136.85 44.0714 137.484 43.4287 137.484 42.6362V32.3C137.484 31.5075 138.117 30.8648 138.898 30.8648H140.733C141.514 30.8648 142.147 30.2221 142.147 29.4297C142.147 28.6373 141.514 27.9946 140.733 27.9946H140.734Z"
            fill="white"
        />
        <path
            d="M154.614 27.9946C154.141 27.9946 153.699 28.2411 153.445 28.6463L151.292 32.087C150.747 32.9595 149.492 32.9556 148.952 32.0806L146.834 28.6528C146.581 28.2437 146.138 27.9946 145.661 27.9946C144.566 27.9946 143.904 29.2245 144.497 30.1589L148.402 36.3059C148.546 36.5331 148.624 36.7964 148.624 37.0661V42.6672C148.624 43.4429 149.243 44.0714 150.008 44.0714H150.183C150.948 44.0714 151.567 43.4429 151.567 42.6672V37.0726C151.567 36.7977 151.646 36.5305 151.795 36.3008L155.771 30.1679C156.377 29.2348 155.717 27.992 154.615 27.992L154.614 27.9946Z"
            fill="white"
        />
    </svg>
);
