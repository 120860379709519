import { SVGProps } from 'react';

export default (props: SVGProps<SVGSVGElement>) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_11332_40529)">
            <path
                d="M0 6.62069C0 2.96418 2.96418 0 6.62069 0H17.3793C21.0358 0 24 2.96418 24 6.62069V17.3793C24 21.0358 21.0358 24 17.3793 24H6.62069C2.96418 24 0 21.0358 0 17.3793V6.62069Z"
                fill="#3C19FD"></path>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M18.6894 9.25425L18.6893 19.1683H15.7349L15.7349 14.3585C14.6751 15.2015 13.4268 15.6407 12 15.6407C10.5732 15.6407 9.3249 15.2015 8.26505 14.3585L8.26514 19.1683H5.3107L5.31072 9.25432C5.74228 9.51604 6.24768 9.66651 6.78792 9.66651C7.32816 9.66651 7.83355 9.51604 8.26512 9.25433L8.26506 9.35438C9.19176 11.6952 10.4087 12.7171 12 12.7171C13.5913 12.7171 14.8083 11.6952 15.7349 9.35438L15.7349 9.25433C16.1664 9.51604 16.6718 9.66651 17.2121 9.66651C17.7524 9.66651 18.2578 9.51602 18.6894 9.25425ZM6.78792 8.54208C7.75771 8.54208 8.54386 7.74916 8.54386 6.77104C8.54386 5.79293 7.75771 5 6.78792 5C5.81814 5 5.03198 5.79293 5.03198 6.77104C5.03198 7.74916 5.81814 8.54208 6.78792 8.54208ZM17.2121 8.54208C18.1819 8.54208 18.968 7.74916 18.968 6.77104C18.968 5.79293 18.1819 5 17.2121 5C16.2423 5 15.4561 5.79293 15.4561 6.77104C15.4561 7.74916 16.2423 8.54208 17.2121 8.54208Z"
                  fill="white"></path>
        </g>
        <defs>
            <clipPath id="clip0_11332_40529">
                <rect width="24" height="24" fill="white"></rect>
            </clipPath>
        </defs>
    </svg>
);
